import React from 'react'
import styled from '@emotion/styled'

const TitleWrapper = styled('div')`
  margin: 0 auto;
  max-width: 679px;
  text-align: center;
  padding-top: 220px;
  padding-bottom: 75px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 127px;
    padding-bottom: 40px;
  }
`

const BlogTitle = styled('h1')`
  font-size: 50px;
  font-weight: 500;
  line-height: 1.35;
  color: #000;
  font-family: ${props => props.theme.fonts.primaryMedium};
  margin: 0;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 24px;
  }
`

const Subtitle = styled('h2')`
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  line-height: 1.35;
  color: #6e6e6e;
  margin: 0;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
    max-width: 260px;
    margin: 0 auto;
  }
`

const Hero = () => (
  <TitleWrapper>
    <BlogTitle>A Digital Studio Blog</BlogTitle>
    <Subtitle>
      Bringing you the latest insights from a leading digital product studio.
    </Subtitle>
  </TitleWrapper>
)

export default Hero
