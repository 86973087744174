import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Hero from '../components/Blog/Hero'
import Featured from '../components/Blog/Featured'
import PostTile from '../components/Blog/PostTile'
import Subscribe from '../components/Blog/Subscribe'
import Pagination from '../components/Blog/Pagination'

const BlogWrapper = styled('div')`
  background: #fff;
  padding-bottom: 100px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-bottom: 60px;
  }
`

const BlogContainer = styled('div')`
  background: white;
  max-width: 1140px;
  padding-left: 74px;
  padding-right: 74px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 30px;
    padding-right: 30px;
  }

  p {
    a {
      color: #000;
    }
  }
`

const PostsWrapper = styled('div')``

const BlogPostsContainer = styled('div')``

const BlogPostsRow = styled('div')`
  margin-left: -23px;
  margin-right: -23px;
  display: flex;
  flex-wrap: wrap;
`

const metaTitle = 'A Digital Product Studio Blog | Crowdlinker'

const metaDescription =
  'Bringing you the latest insights from our experience of building amazing digital products and leading digital transformation.'

const Blog = props => {
  const posts = props.data.allContentfulBlogPost.edges
  const blogPageData = props.data.contentfulBlogHomepage
  const excludeFeaturedPostID = blogPageData.featuredPost.id

  const { skip, limit, currentPage, numPages } = props.pageContext

  const isBlogHomepage = currentPage === 1
  const subscribeLocation = isBlogHomepage ? 2 : 2

  const sliceTo = isBlogHomepage ? 6 : 6 + limit * (currentPage - 1)

  return (
    <Layout footerCTA={true}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content="https://crowdlinker.com/blog/" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <StickyNav />
      <BlogWrapper>
        <BlogContainer>
          <Hero />
          <PostsWrapper>
            {isBlogHomepage && <Featured />}
            <BlogPostsContainer>
              <BlogPostsRow>
                {posts
                  .filter(({ node }) => excludeFeaturedPostID !== node.id)
                  .slice(skip, sliceTo)
                  .map(({ node }, i) => {
                    return i === subscribeLocation ? (
                      <React.Fragment key="fragment">
                        <Subscribe
                          key="newsletter-subscribe"
                          ctaData={blogPageData}
                        />
                        <PostTile key={node.id} {...node} />
                      </React.Fragment>
                    ) : (
                      <PostTile key={node.id} {...node} />
                    )
                  })}
              </BlogPostsRow>
            </BlogPostsContainer>
          </PostsWrapper>
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            url={`/blog`}
          />
        </BlogContainer>
      </BlogWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM D, YYYY")
          category {
            title
            id
          }
          readingTime
          description
          createdAt
          image {
            sizes(maxWidth: 994, maxHeight: 624, cropFocus: CENTER) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    }
    contentfulBlogHomepage {
      title
      featuredPost {
        title
        id
        description
        slug
        category {
          title
          id
        }
        date(formatString: "MMMM D, YYYY")
        readingTime
        image {
          description
          fluid {
            src
          }
        }
      }
      ctaTitle
      ctaDescription
      ctaPlaceholder
      ctaButtonText
      ctaSuccessHeader
      ctaSuccessBody
    }
  }
`

export default Blog
