import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const FeaturedPost = styled('div')`
  display: block;
  position: relative;
`

const FeaturedPostImage = styled(Img)`
  width: 100%;
  max-width: 100%;
  height: auto;
`

const FeaturedPostContent = styled('div')`
  margin-top: -70px;
  padding-bottom: 98px;
  position: relative;
  z-index: 2;
  max-width: 472px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-bottom: 70px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-bottom: 40px;
    margin-top: -40px;
  }
`

const FeaturedPostImageWrapper = styled('div')`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      8deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.3) 36%,
      rgba(237, 237, 237, 0) 60%
    );
    background: -webkit-linear-gradient(
      8deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.3) 36%,
      rgba(237, 237, 237, 0) 60%
    );
    background: -o-linear-gradient(
      8deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.3) 36%,
      rgba(237, 237, 237, 0) 60%
    );
    background: -ms-linear-gradient(
      8deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.3) 36%,
      rgba(237, 237, 237, 0) 60%
    );
    background: linear-gradient(
      8deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.3) 36%,
      rgba(237, 237, 237, 0) 60%
    );
  }
`

export const Category = styled('span')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 13px;
  letter-spacing: 0.8px;
  color: #747474;
  text-transform: uppercase;
  margin: 0 0 4px;
`

export const Title = styled('p')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 22px;
  font-weight: 500;
  line-height: 1.18;
  color: #000;
  margin: 0 0 7px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
  }
`

export const Description = styled('div')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 18px;
  line-height: 1.22;
  color: #484848;
`

export const PostLink = styled(Link)`
  color: #000;
  text-decoration: none;
  line-height: 1.4;
  background-image: linear-gradient(#000, #000);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  &:hover,
  &:focus {
    background-size: 100% 2px;
  }
`

export const PostInfo = styled('div')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  color: #000000;
  padding-top: 12px;
  display: block;

  strong {
    padding: 0 7px;
  }

  span {
    font-weight: bold;
  }
`

const Featured = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulBlogHomepage {
          title
          featuredPost {
            title
            id
            description
            category {
              title
              id
            }
            slug
            date(formatString: "MMMM D, YYYY")
            readingTime
            image {
              sizes(maxWidth: 1992, quality: 90) {
                ...GatsbyContentfulSizes
              }
            }
          }
        }
      }
    `}
    render={({ contentfulBlogHomepage }) => {
      const {
        title,
        readingTime,
        category,
        description,
        slug,
        date,
        image,
      } = contentfulBlogHomepage.featuredPost
      return (
        <FeaturedPost>
          <Link to={`/blog/` + slug}>
            <FeaturedPostImageWrapper>
              <FeaturedPostImage sizes={image.sizes} alt={image.description} />
            </FeaturedPostImageWrapper>
          </Link>
          <FeaturedPostContent>
            <Category>{category.title}</Category>
            <Title>
              <PostLink to={`/blog/` + slug}>{title}</PostLink>
            </Title>
            <Description>{description}</Description>
            <PostInfo>
              {date} <strong>•</strong>
              <span>{readingTime} min read</span>
            </PostInfo>
          </FeaturedPostContent>
        </FeaturedPost>
      )
    }}
  />
)
export default Featured
