import React from 'react'
import styled from '@emotion/styled'

const Overlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 99%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.2);
`

export default function OpacityOverlay() {
  return <Overlay />
}
