import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const bounce = keyframes`
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
  75% {
    transform: translateY(0);
  }
`

const DotsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Dot = styled('div')`
  width: 16px;
  height: 16px;
  background-color: #76bc21;
  margin-right: 13px;
  border-radius: 50%;
  animation: ${bounce} 1s linear infinite;
  &:nth-child(2) {
    background-color: #1f81e6;
    animation-delay: 0.15s;
  }
  &:nth-child(3) {
    background-color: #c8238f;
    animation-delay: 0.31s;
  }
`

export default function DotLoading() {
  return (
    <DotsContainer>
      <Dot />
      <Dot />
      <Dot />
    </DotsContainer>
  )
}
