import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const PaginationWrapper = styled('div')`
  display: flex;
  justify-content: center;
`

const PageLink = styled(Link)`
  font-size: 18px;
  margin: 0 17px;
  color: #000;
  text-decoration: ${props => (props.active ? `underline` : 'none')};
  &:hover {
    color: #9b9b9b;
  }
`

class Pagination extends React.Component {
  state = {
    firstThreeArray: [1],
    lastNumber: '',
    showEllipis: true,
  }

  componentDidMount() {
    const { numPages, currentPage } = this.props
    const fArray = []

    if (this.props.numPages <= 5) {
      for (var i = 1; i <= numPages; i++) {
        fArray.push(i)
      }
      this.setState({ firstThreeArray: fArray })
    } else {
      if (currentPage < 3) {
        this.setState({ firstThreeArray: [1, 2, 3] })
      } else {
        if (currentPage === numPages) {
          fArray.push(currentPage - 2)
        }
        fArray.push(currentPage - 1)
        fArray.push(currentPage)
        if (currentPage + 1 < numPages) {
          fArray.push(currentPage + 1)
        }
        if (numPages - currentPage <= 2) {
          this.setState({ showEllipis: false })
        } else {
          this.setState({ showEllipis: true })
        }
        this.setState({ firstThreeArray: fArray })
      }
      this.setState({ lastNumber: numPages })
    }
  }

  isactive = pageNumber =>
    this.props.currentPage === pageNumber ? true : false

  render() {
    const { showEllipis, firstThreeArray } = this.state
    const { currentPage, numPages, url } = this.props

    const renderPageNumbers = firstThreeArray.map((no, index) => {
      const linkUrl = no === 1 ? url : `${url}/${no}`
      return (
        <PageLink
          to={linkUrl}
          key={index}
          {...this.isactive(no) && { active: 'true' }}
        >
          {no}
        </PageLink>
      )
    })

    return (
      <PaginationWrapper>
        {renderPageNumbers}
        {numPages > 5 && (
          <React.Fragment>
            {showEllipis && <span>...</span>}
            {currentPage !== numPages && (
              <PageLink
                to={`${url}/${numPages}`}
                {...this.isactive(numPages) && { active: 'true' }}
              >
                {numPages}
              </PageLink>
            )}
          </React.Fragment>
        )}
      </PaginationWrapper>
    )
  }
}
export default Pagination
