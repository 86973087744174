import React from 'react'
import axios from 'axios'
import styled from '@emotion/styled'
import { CTA, CTAText } from '../Buttons/index'
import planeSVG from '../../images/plane.svg'
import { checkEmailValid } from '../../util/checkEmailValid'
import DotLoading from '../UI/DotLoading'
import OpacityOverlay from '../UI/OpacityOverlay'
import gtmEvent from '../../util/gtmEvent'

const SubscribeWrapper = styled('div')`
  flex: 1;
  flex-basis: 100%;
  padding: ${props => (props.useAsBanner ? '0' : '45px 23px 65px')};
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: ${props => (props.useAsBanner ? '0' : '40px 23px 80px')};
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: ${props => (props.useAsBanner ? '0' : '10px 23px 50px')};
  }
`

const SubscribeContainer = styled('div')`
  position: relative;
  background: #000;
  padding: ${props => (props.useAsBanner ? '60px 18%' : '70px 59px')};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    padding: 70px 74px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 40px 30px;
  }
`

const SubscribeBox = styled('div')`
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 95%;
    margin: auto;
  }
`

const Title = styled('h3')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.35;
  margin: 0 0 7px;
  max-width: 521px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 401px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 23px;
    margin: 0 0 18px;
  }
`

const Description = styled('p')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 20px;
  line-height: 1.25;
  color: #fff;
  max-width: 460px;
  margin: 0;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 401px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 100%;
  }
`

const SubscribeForm = styled('form')`
  display: flex;
  margin-top: 43px;
  justify-content: space-between;
  padding-right: 46px;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-right: 0;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    align-items: flex-start;
    flex-direction: column;
  }
`

const InputWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  flex: 1;
  margin-right: 72px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    margin: 0;
  }
`

const EmailInput = styled('input')`
  border: 0;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  color: #c3c3c3;
  font-weight: 300;
  background: transparent;
  outline: none;
  appearance: none;
  webkit-appearance: none;
  border-radius: 0;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.secondary};
  margin-right: 71px;
  &:focus + span {
    left: 0;
  }
`

const Underline = styled('span')`
  transition: all 0.4s;
  display: inline-block;
  bottom: 0;
  left: -110%;
  position: absolute;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #601947, #315567);
`

const SubscribeButton = CTA.withComponent('button')

const SubscribeButtonInner = styled(CTAText)`
  padding: 0 19px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0 10px;
  }
`

const ErrorMessage = styled('p')`
  color: ${props => props.errorColor};
  position: absolute;
  margin-top: 31px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: none;
  }
`

const MobileErrorMessage = styled('p')`
  display: none;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 0;
    display: block;
    height: 24px;
    color: ${props => props.errorColor};
    opacity: ${props => (props.error ? 1 : 0)};
  }
`

const SuccessHeader = styled('h2')`
  color: #fff;
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 24px;
  font-weight: 500;
`

const SuccessBodyContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-direction: column;
  }
`

const SuccessBody = styled('p')`
  color: #fff;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 20px;
  line-height: 1.25;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 40px;
    max-width: 60%;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 100%;
    margin: 39px auto 36px;
  }
`

const PlaneImage = styled('img')`
  width: 141px;
  margin-right: 6%;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 154px;
    margin: 0 auto;
  }
`

const LoadingContainer = styled('div')`
  position: absolute;
  right: 0;
  left: 0;
  transform: translateY(-56px);
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    transform: translateY(-47px);
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    transform: translateY(-30px);
  }
`

class Subscribe extends React.Component {
  state = {
    email_address: '',
    isLoading: false,
    isSubmitted: false,
    error: '',
    errorColor: '',
  }

  handleEmailInput = e => this.setState({ email_address: e.target.value })

  handleSubmit = e => {
    e.preventDefault()

    const { email_address } = this.state

    if (!checkEmailValid(email_address)) {
      this.setState({
        error: 'Please enter a valid email address',
        errorColor: 'red',
      })
      return
    }

    this.setState({ isLoading: true })
    axios
      .post(`${process.env.GATSBY_LAMBDA_API_URL}/subscribe`, { email_address })
      .then(() => {
        setTimeout(() => {
          this.setState({ isSubmitted: true, isLoading: false })
        }, 1000)
        gtmEvent(
          'Blog Subscription',
          'subscribe',
          'Blog Subscribe',
          window.location.href
        )
      })
      .catch(e => {
        this.setState({ isLoading: false })
        const errorMessage = e.response.data.title
        if (errorMessage === 'Member Exists') {
          this.setState({
            error: 'You are already subscribed to our mailing list',
            errorColor: 'green',
          })
        } else if (errorMessage === 'Invalid Resource') {
          this.setState({
            error: 'Please enter a valid email address',
            errorColor: 'red',
          })
        } else {
          this.setState({
            error: 'Whoops something went wrong on our end',
            errorColor: 'red',
          })
        }
      })
  }

  render() {
    const { isLoading, isSubmitted, error, errorColor } = this.state
    const {
      ctaTitle,
      ctaDescription,
      ctaPlaceholder,
      ctaButtonText,
      ctaSuccessHeader,
      ctaSuccessBody,
    } = this.props.ctaData
    const { useAsBanner } = this.props

    return (
      <SubscribeWrapper useAsBanner={useAsBanner}>
        <SubscribeContainer useAsBanner={useAsBanner}>
          {isLoading && <OpacityOverlay />}
          {isSubmitted ? (
            <SubscribeBox>
              <SuccessHeader>{ctaSuccessHeader}</SuccessHeader>
              <SuccessBodyContainer>
                <SuccessBody>{ctaSuccessBody}</SuccessBody>
                <PlaneImage src={planeSVG} alt="plane" />
              </SuccessBodyContainer>
            </SubscribeBox>
          ) : (
            <SubscribeBox>
              <Title>{ctaTitle}</Title>
              <Description>{ctaDescription}</Description>
              <SubscribeForm onSubmit={this.handleSubmit}>
                <InputWrapper>
                  <EmailInput
                    type="text"
                    placeholder={ctaPlaceholder}
                    onChange={this.handleEmailInput}
                  />
                  <Underline />
                </InputWrapper>
                <MobileErrorMessage errorColor={errorColor} error={!!error}>
                  {error}
                </MobileErrorMessage>
                <SubscribeButton
                  height="40px"
                  fontSize="16px"
                  gradientTop="1px"
                  gradientHeight="calc(100% - 2px)"
                  gradientWidth="calc(100% - 4px)"
                  gradientRadius="30.5px"
                  mobileFullWidth={true}
                >
                  <SubscribeButtonInner>{ctaButtonText}</SubscribeButtonInner>
                </SubscribeButton>
                {error && (
                  <ErrorMessage errorColor={errorColor}>{error}</ErrorMessage>
                )}
                {isLoading && (
                  <LoadingContainer>
                    <DotLoading />
                  </LoadingContainer>
                )}
              </SubscribeForm>
            </SubscribeBox>
          )}
        </SubscribeContainer>
      </SubscribeWrapper>
    )
  }
}

export default Subscribe
