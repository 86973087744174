import React from 'react'
import styled from '@emotion/styled'
import { Category, Title, PostInfo, PostLink, Description } from './Featured'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const BlogPost = styled('div')`
  padding-left: 23px;
  padding-right: 23px;
  display: block;
  flex-basis: 50%;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-basis: 100%;
  }
`

const BlogPostImageWrap = styled('div')`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      13deg,
      rgba(255, 255, 255, 1) 17%,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(237, 237, 237, 0) 50%
    );
    background: -webkit-linear-gradient(
      13deg,
      rgba(255, 255, 255, 1) 17%,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(237, 237, 237, 0) 50%
    );
    background: -o-linear-gradient(
      13deg,
      rgba(255, 255, 255, 1) 17%,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(237, 237, 237, 0) 50%
    );
    background: -ms-linear-gradient(
      13deg,
      rgba(255, 255, 255, 1) 17%,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(237, 237, 237, 0) 50%
    );
    background: linear-gradient(
      13deg,
      rgba(255, 255, 255, 1) 17%,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(237, 237, 237, 0) 50%
    );
  }
`

const BlogPostImage = styled(Img)`
  max-width: 100%;
  height: auto;
`

const BlogPostContent = styled('div')`
  margin-top: -40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
  max-width: 369px;
`

const PostTile = ({
  title,
  category,
  slug,
  readingTime,
  description,
  date,
  image,
}) => {
  return (
    <BlogPost>
      <Link to={`/blog/` + slug}>
        <BlogPostImageWrap>
          <BlogPostImage sizes={image.sizes} alt={image.description} />
        </BlogPostImageWrap>
      </Link>
      <BlogPostContent>
        {category ? <Category>{category.title}</Category> : null}
        <Title>
          <PostLink to={`/blog/` + slug}>{title}</PostLink>
        </Title>
        <Description>{description}</Description>
        <PostInfo>
          {date} <strong>•</strong>
          <span>{readingTime} min read</span>
        </PostInfo>
      </BlogPostContent>
    </BlogPost>
  )
}

export default PostTile
